import * as z from 'zod';
import { useAuthContext } from '../contexts/AuthContext';
import { LoginStatus } from '../types/authTypes';
import { PersistenceStorage } from '../utils/storeUtils';

export const SelectedArtistPersistence = PersistenceStorage({
  schema: z.object({
    artistHandle: z.string(),
    artistId: z.string(),
  }),
  key: 'selectedArtist',
  eager: true,
});

type SelectedArtistState =
  | { status: 'loading' }
  | { status: 'ready'; artistHandle: string; artistId: string }
  | { status: 'error' };

export const useSelectedArtist = (): SelectedArtistState => {
  const { loggedInUser, loginStatus } = useAuthContext();
  const { isLoading, value } = SelectedArtistPersistence.useStore();

  if (isLoading || loginStatus === LoginStatus.LOADING) {
    return { status: 'loading' };
  }

  if (!!value) {
    return {
      status: 'ready',
      artistHandle: value.artistHandle,
      artistId: value.artistId,
    };
  }

  if (!!loggedInUser?.artist) {
    return {
      status: 'ready',
      artistHandle: loggedInUser.artist.mainLinkValue,
      artistId: loggedInUser.artist.id,
    };
  }

  return { status: 'error' };
};
