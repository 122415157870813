import { type Dispatch, type SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Virtuoso } from 'react-virtuoso';
import { faCheck, faSearch, faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { US_STATE_CODES } from '../../constants/bottomsheetConstants';
import { COUNTRY_CODES } from '../../constants/phoneConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import {
  type IsoCountry,
  type IsousState,
  LocationMemberCountsDocument,
} from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import type { ChooseLocationBottomsheetProps } from '../../types/bottomsheetTypes';
import { getManyFromList } from '../../utils/arrayUtils';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

gql(/* GraphQL */ `
  query LocationMemberCounts($artistId: String!) {
    memberCountsByCountry(artistId: $artistId) {
      __typename
      ... on QueryMemberCountsByCountrySuccess {
        data {
          isoCountryCode
          isoUsStateCode
          memberCount
        }
      }
    }
    memberCountsByUSState(artistId: $artistId) {
      __typename
      ... on QueryMemberCountsByUSStateSuccess {
        data {
          isoCountryCode
          isoUsStateCode
          memberCount
        }
      }
    }
  }
`);

export function ChooseLocationBottomsheet({
  locations: initialLocations,
  onSetLocations: setReturnLocations,
  setIsScrolling,
  artistHandle, //Only include if you want to filter the locations by the artist's members
}: ChooseLocationBottomsheetProps & { setIsScrolling: (isScrolling: boolean) => void }) {
  const { loggedInUser } = useAuthContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const [locations, setLocations] =
    useState<{ code: IsoCountry; regionCode: IsousState | null }[]>(initialLocations);

  const [step, setStep] = useState<'countries' | 'states'>('countries');

  const [searchText, setSearchText] = useState('');

  const containsUS = locations.some(location => location.code === 'US');

  const onChoose = () => {
    setReturnLocations(locations);
  };

  const artistId = loggedInUser?.adminArtists?.find(({ artistLinks }) =>
    artistLinks.includes(artistHandle ?? ''),
  )?.artistId;

  const { data: memberCounts, isLoading } = useQuery(LocationMemberCountsDocument, {
    variables: !!artistId && { artistId },
    staleTime: 0,
    select: data => {
      if (
        data.data.memberCountsByCountry.__typename === 'NotAuthorizedError' ||
        data.data.memberCountsByUSState.__typename === 'NotAuthorizedError'
      ) {
        return null;
      }

      return {
        memberCountsByCountry: getManyFromList(
          data.data.memberCountsByCountry.data,
          ({ isoCountryCode, isoUsStateCode, memberCount }) => {
            if (isoCountryCode == null) return null;

            return { isoCountryCode, isoUsStateCode, memberCount };
          },
        ),
        memberCountsByUSState: getManyFromList(
          data.data.memberCountsByUSState.data,
          ({ isoCountryCode, isoUsStateCode, memberCount }) => {
            if (isoCountryCode == null || isoUsStateCode == null) return null;

            return { isoCountryCode, isoUsStateCode, memberCount };
          },
        ),
      };
    },
  });

  const waitForLoad = !!artistHandle && isLoading;

  const renderItem = useStableCallback(
    (
      _index: number,
      data:
        | ((typeof COUNTRY_CODES)[number] & {
            type: 'COUNTRY';
            memberCount: number | null | undefined;
          })
        | ((typeof US_STATE_CODES)[number] & {
            type: 'STATE';
            memberCount: number | null | undefined;
          }),
    ) => {
      if (data.type === 'COUNTRY') {
        return (
          <CountryItem
            data={data}
            setCountries={setLocations}
            isSelected={locations.some(location => location.code === data.code)}
          />
        );
      }

      return (
        <StateItem
          data={data}
          setStates={setLocations}
          isSelected={locations.some(location => location.regionCode === data.code)}
        />
      );
    },
  );

  const countries = useMemo(() => {
    if (!!artistHandle) {
      return getManyFromList(
        memberCounts?.memberCountsByCountry ?? [],
        ({ isoCountryCode, memberCount }) => {
          const country = COUNTRY_CODES.find(({ code }) => code === isoCountryCode);

          if (country == null) return null;

          return { ...country, type: 'COUNTRY', memberCount } as const;
        },
      );
    }

    return getManyFromList(COUNTRY_CODES, country => {
      return { ...country, type: 'COUNTRY', memberCount: 0 } as const;
    });
  }, [artistHandle, memberCounts?.memberCountsByCountry]);

  const states = useMemo(() => {
    if (!!artistHandle) {
      return getManyFromList(
        memberCounts?.memberCountsByUSState ?? [],
        ({ isoUsStateCode, memberCount }) => {
          const state = US_STATE_CODES.find(({ code }) => code === isoUsStateCode);

          if (state == null) return null;

          return { ...state, type: 'STATE', memberCount } as const;
        },
      );
    }

    return getManyFromList(US_STATE_CODES, state => {
      return { ...state, type: 'STATE', memberCount: 0 } as const;
    });
  }, [artistHandle, memberCounts?.memberCountsByUSState]);

  const data = useMemo(() => {
    if (waitForLoad) return [];

    if (step === 'countries') {
      return getManyFromList(countries, country => {
        if (!!searchText) {
          if (country.name.toLowerCase().includes(searchText.toLowerCase())) {
            return {
              ...country,
              type: 'COUNTRY',
            } as const;
          }
          return null;
        }

        return {
          ...country,
          type: 'COUNTRY',
        } as const;
      }).toSorted((a, b) => {
        if (!searchText) {
          if (a.code === 'US') return -1;
          if (b.code === 'US') return 1;
        }

        return a.name.localeCompare(b.name);
      });
    }

    return getManyFromList(states, state => {
      if (!!searchText) {
        if (state.name.toLowerCase().includes(searchText.toLowerCase())) {
          return {
            ...state,
            type: 'STATE',
          } as const;
        }
        return null;
      }

      return {
        ...state,
        type: 'STATE',
      } as const;
    }).toSorted((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }, [countries, searchText, states, step, waitForLoad]);

  const EmptyState = useCallback(() => {
    if (waitForLoad) {
      return (
        <View className="flex h-full w-full items-center justify-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="5x"
            className="inline-block animate-spin items-center self-center rounded-full font-medium text-vault_text/50"
          />
        </View>
      );
    }

    return null;
  }, [waitForLoad]);

  const Header = useCallback(() => {
    if (!!searchText || waitForLoad) return null;

    if (step === 'countries') {
      const isEmpty = locations.length === 0;
      return (
        <View
          className="box-border flex w-full items-center justify-between border-0 border-b border-solid border-vault_text/15 px-4 py-5"
          onClick={() => {
            setLocations([]);
          }}
        >
          <View className="flex items-center gap-3">
            <Text className="font-title text-[16px]/[20px] font-medium text-vault_text">🌏</Text>
            <Text className="font-title text-[16px]/[20px] font-medium text-vault_text">
              Global
            </Text>
          </View>
          {isEmpty && <FontAwesomeIcon icon={faCheck} className="text-[20px] text-vault_text" />}
        </View>
      );
    }

    const isEmpty = locations.filter(location => location.regionCode != null).length === 0;

    return (
      <View
        className="box-border flex w-full items-center justify-between border-0 border-b border-solid border-vault_text/15 px-4 py-5"
        onClick={() => {
          setLocations(_locations => [
            ..._locations.filter(location => location.regionCode == null),
            { code: 'US', regionCode: null },
          ]);
        }}
      >
        <View className="flex items-center gap-3">
          <Text className="font-title text-[16px]/[20px] font-medium text-vault_text">All</Text>
        </View>
        {isEmpty && <FontAwesomeIcon icon={faCheck} className="text-[20px] text-vault_text" />}
      </View>
    );
  }, [locations, searchText, step, waitForLoad]);

  return (
    <View className="my-4 flex h-[75vh] w-full flex-col items-center bg-vault_background md2:h-[50vh]">
      <Text className="mb-8 font-title text-[18px]/[22px] font-medium text-vault_text">
        Choose {step === 'countries' ? 'country' : 'U.S. state'}
      </Text>
      <View
        className="mb-4 box-border flex h-12 w-full items-center justify-start gap-4 rounded-xl bg-vault_text/15 px-5 py-3"
        onClick={() => inputRef.current?.focus()}
      >
        <FontAwesomeIcon icon={faSearch} className="text-[20px] text-vault_text/60" />
        <input
          placeholder={step === 'countries' ? 'Search countries' : 'Search U.S. states'}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          className="w-full flex-1 border-none bg-transparent text-base-l text-vault_text outline-none placeholder:text-vault_text/60"
          ref={inputRef}
        />
      </View>
      <View className="w-full flex-1 border-0 border-b border-solid border-vault_text/15 px-4">
        <Virtuoso
          className="no-scrollbar"
          data={data}
          itemContent={renderItem}
          onScroll={() => {
            setIsScrolling(true);
          }}
          onTouchEnd={() => {
            setIsScrolling(false);
          }}
          components={{
            Header,
            EmptyPlaceholder: EmptyState,
          }}
        />
      </View>
      <Button
        label={containsUS && step === 'countries' ? 'Next' : 'Choose'}
        onClick={containsUS && step === 'countries' ? () => setStep('states') : onChoose}
        type="primary-themed"
        className="mt-4 w-full"
        disabled={waitForLoad}
        disabledClassName="opacity-50"
      />
    </View>
  );
}

function CountryItem({
  data,
  setCountries,
  isSelected,
}: {
  data: (typeof COUNTRY_CODES)[number] & { memberCount: number | null | undefined };
  setCountries: Dispatch<SetStateAction<{ code: IsoCountry; regionCode: IsousState | null }[]>>;
  isSelected: boolean;
}) {
  return (
    <View
      className="box-border flex w-full cursor-pointer items-center justify-between border-0 border-b border-solid border-vault_text/15 px-4 py-5"
      onClick={() => {
        if (isSelected) {
          setCountries(prev => prev.filter(location => location.code !== data.code));
          return;
        }

        setCountries(prev => [...prev, { code: data.code, regionCode: null }]);
      }}
    >
      <View className="flex items-center gap-3">
        <Text className="font-title text-[16px]/[20px] font-medium text-vault_text">
          {data.flag}
        </Text>
        <View className="flex flex-col items-start justify-start transition-all">
          <Text className="font-title text-[16px]/[20px] font-medium text-vault_text">
            {data.name}
          </Text>
          {!!data.memberCount && (
            <Text className="font-title text-[12px]/[16px] font-medium text-vault_text/60">
              {data.memberCount} members
            </Text>
          )}
        </View>
      </View>
      {isSelected && <FontAwesomeIcon icon={faCheck} className="text-[20px] text-vault_text" />}
    </View>
  );
}

function StateItem({
  data,
  setStates,
  isSelected,
}: {
  data: (typeof US_STATE_CODES)[number] & { memberCount: number | null | undefined };
  setStates: Dispatch<SetStateAction<{ code: IsoCountry; regionCode: IsousState | null }[]>>;
  isSelected: boolean;
}) {
  return (
    <View
      className="box-border flex w-full cursor-pointer items-center justify-between border-0 border-b border-solid border-vault_text/15 px-4 py-5"
      onClick={() => {
        if (isSelected) {
          setStates(prev => {
            const filtered = prev.filter(location => location.regionCode !== data.code);

            if (filtered.some(location => location.code === 'US')) {
              return filtered;
            }

            return [...filtered, { code: 'US', regionCode: null }];
          });
          return;
        }

        setStates(prev => {
          const filtered = prev.filter(
            location =>
              location.code !== 'US' || (location.code === 'US' && location.regionCode != null),
          );

          return [...filtered, { code: 'US', regionCode: data.code }];
        });
      }}
    >
      <View className="flex items-center gap-3">
        <View className="flex flex-1 flex-shrink flex-col items-start justify-start transition-all">
          <Text className="font-title text-[16px]/[20px] font-medium text-vault_text">
            {data.name}
          </Text>
          {!!data.memberCount && (
            <Text className="font-title text-[14px]/[18px] font-normal text-vault_text/60">
              {data.memberCount} members
            </Text>
          )}
        </View>
      </View>
      {isSelected && <FontAwesomeIcon icon={faCheck} className="text-[20px] text-vault_text" />}
    </View>
  );
}
