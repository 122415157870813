import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import millify from 'millify';
import { Link } from 'react-router-dom';
import {
  faCalendarDays,
  faReceipt,
  faWaveformLines,
} from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faCheckCircle } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import {
  type FragmentType,
  getFragment,
  RsvpEventItemFragmentDoc,
  RsvpEventStatus,
} from '../../../graphql/generated';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { Image } from '../../common/Image';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

export const RsvpEventItem = React.memo(
  ({
    eventId,
    rsvpEvent,
  }: {
    eventId: string;
    rsvpEvent: FragmentType<RsvpEventItemFragmentDoc>;
  }) => {
    const { loggedInUser } = useAuthContext();

    const rsvpFragment = getFragment(RsvpEventItemFragmentDoc, rsvpEvent);

    if (
      rsvpFragment.__typename !== 'PublicRsvpEvent' &&
      rsvpFragment.__typename !== 'RsvpEventPrivateInfo'
    ) {
      return null;
    }

    const {
      title,
      linkValue,
      status,
      coverImage,
      artist: { isAuthUserAdmin, linkValue: artistHandle, profileImage },
    } = rsvpFragment;

    const rsvpImage = coverImage?.url ?? profileImage?.url;

    const isOwner = loggedInUser?.adminArtists?.some(({ artistLinks }) =>
      artistLinks.includes(artistHandle),
    );

    const shouldShowNotVisibleToFansSubtext = isOwner && status !== RsvpEventStatus.Active;

    const receiptCount =
      rsvpFragment.__typename === 'RsvpEventPrivateInfo' ? rsvpFragment.receiptCount : 0;

    const alreadyRsvpd =
      rsvpFragment.__typename === 'PublicRsvpEvent' ? rsvpFragment.userRsvp.length > 0 : false;

    return (
      <Link
        to={`${artistNavigationPath(
          artistHandle,
          isAuthUserAdmin ? `/event/insights/${eventId}` : `/d/${linkValue}`,
        )}`}
        className="flex w-full flex-row items-center justify-between gap-4 no-underline transition-all duration-200 ease-in hover:opacity-80"
      >
        <View className="flex h-16 max-w-[80%] justify-start gap-4">
          {rsvpImage ? (
            <Image
              src={rsvpImage}
              alt={title}
              height={64}
              width={64}
              className="aspect-square h-16 w-16 flex-shrink-0 rounded-md bg-neutral500 object-cover"
            />
          ) : (
            <View className="flex aspect-square w-16 flex-shrink-0 items-center justify-center rounded-md outline outline-1 outline-vault_text/10">
              <FontAwesomeIcon icon={faWaveformLines} className="text-[20px] text-vault_text" />
            </View>
          )}
          <View className="flex w-full flex-col justify-center gap-2">
            <View className="flex flex-row items-center gap-2">
              <Text className="line-clamp-1 font-title text-[18px]/[20px] font-medium text-vault_text">
                {title}
              </Text>
            </View>
            <View className="flex flex-col gap-0.5">
              <span className="flex items-center gap-1 font-base text-[14px]/[16px] font-normal">
                {alreadyRsvpd ? (
                  <span className="flex items-center gap-1 font-base text-[14px]/[16px] font-normal">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-vault_accent" />
                    <Text className="font-base text-vault_accent">RSVP&apos;d</Text>
                  </span>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCalendarDays} className="text-vault_text/60" />
                    <Text className="mt-[2px] font-base text-vault_text/60">
                      RSVP {shouldShowNotVisibleToFansSubtext ? '• Canceled' : ''}
                    </Text>
                  </>
                )}
              </span>
            </View>
          </View>
        </View>
        {isAuthUserAdmin && (
          <View className="flex w-12 flex-shrink-0 flex-row items-center justify-end gap-2 pr-2 text-[14px] font-normal text-vault_text opacity-60">
            <View className="line-clamp-1 flex flex-shrink flex-row justify-end text-[14px]">
              <Text>{millify(receiptCount, { precision: 1, lowercase: true })}</Text>
            </View>
            <FontAwesomeIcon icon={faReceipt} className="text-[14px]" />
          </View>
        )}
      </Link>
    );
  },
);

RsvpEventItem.displayName = 'RsvpEvent';
