import { useState } from 'react';
import { Statsig, useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faTools } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';

export const FeatureFlagToggle = () => {
  const [isOpen, setIsOpen] = useState(false);

  const isDevPrev =
    import.meta.env.VITE_DEPLOY_ENVIRONMENT === 'development' ||
    import.meta.env.VITE_DEPLOY_ENVIRONMENT === 'preview';

  if (!isDevPrev) {
    return null;
  }

  const featureGates = Object.values(FEATURE_GATES);
  const gateValues = Object.fromEntries(featureGates.map(gate => [gate, useGate(gate).value]));

  const toggleFeature = (gate: string) => {
    Statsig.overrideGate(gate, !gateValues[gate]);
  };

  return (
    <>
      <Button
        className="fixed bottom-4 right-4 z-previewFrame rounded-full bg-vault_accent p-3 shadow-lg"
        onClick={() => setIsOpen(!isOpen)}
        iconOnly
        leadingIcon={faTools}
        label="Toggle Feature Flags"
      />

      {isOpen && (
        <View className="fixed bottom-20 right-4 z-previewFrame w-80 rounded-lg bg-vault_text/10 p-4 shadow-lg backdrop-blur-2xl">
          <Text className="text-lg mb-4 font-title font-bold">Feature Flags</Text>
          <View className="flex flex-col gap-2">
            {featureGates.map(gate => (
              <View key={gate} className="flex items-center justify-between">
                <Text className="text-sm">{gate}</Text>
                <button
                  className={twMerge(
                    'rounded px-3 py-1 text-vault_text',
                    gateValues[gate] ? 'bg-green100' : 'bg-destructive100',
                  )}
                  onClick={() => toggleFeature(gate)}
                >
                  <Text>{gateValues[gate] ? 'ON' : 'OFF'}</Text>
                </button>
              </View>
            ))}
          </View>
        </View>
      )}
    </>
  );
};
