import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatDate } from 'date-fns';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { faPenToSquare } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { ArtistProfileImage } from '../../../components/artist/ArtistProfileImage';
import { BackButton } from '../../../components/buttons/BackButton';
import { Button } from '../../../components/buttons/Button';
import { Text } from '../../../components/common/Text';
import { View } from '../../../components/common/View';
import { MassMessageInsightsView } from '../../../components/massMessage/MassMessageInsightsView';
import { ROUTES } from '../../../constants/routeConstants';
import {
  AnnouncementRowFragmentDoc,
  type FragmentType,
  getFragment,
} from '../../../graphql/generated';
import { usePaginatedVaultAnnouncements } from '../../../hooks/announcements/usePaginatedVaultAnnouncements';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useOwnerOnlyAccess } from '../../../hooks/useOwnerOnlyAcess';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { useWindow } from '../../../hooks/useWindow';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { pluralizeText } from '../../../utils/textUtils';

export function MassMessagesPage() {
  const { artistHandle } = useArtistHandle();

  const [searchParams, setSearchParams] = useSearchParams();

  const messageId = searchParams.get('messageId');

  const [selectedMessageId, setSelectedMessageId] = useState<string | null>(messageId ?? null);

  const {
    orderedList: announcements,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = usePaginatedVaultAnnouncements({ artistHandle, enabled: !!artistHandle });

  const { isLg } = useWindow();

  useOwnerOnlyAccess();

  useVaultTheme();

  useEffect(() => {
    if (!isLg) return;

    if (announcements.length === 0) return;

    if (messageId != null && announcements.some(a => a.id === messageId)) {
      setSelectedMessageId(messageId);
      setSearchParams({}, { replace: true });
      return;
    }

    if (selectedMessageId == null || !announcements.some(a => a.id === selectedMessageId)) {
      setSelectedMessageId(announcements[0]?.id ?? null);
    }
  }, [announcements, isLg, messageId, selectedMessageId, setSearchParams]);

  const Footer = useCallback(() => <View className="h-10" />, []);

  const selectedMessage = useMemo(() => {
    if (selectedMessageId == null) return null;

    return announcements.find(a => a.id === selectedMessageId);
  }, [announcements, selectedMessageId]);

  const renderItem = useCallback(
    (_index: number, announcement: FragmentType<AnnouncementRowFragmentDoc> & { id: string }) => {
      if (!artistHandle) return null;

      return (
        <ArtistAnnouncementRow
          announcement={announcement}
          artistHandle={artistHandle}
          isSelected={selectedMessageId === announcement.id}
          onSelect={setSelectedMessageId}
        />
      );
    },
    [artistHandle, selectedMessageId],
  );

  if (!artistHandle) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <View className="box-border flex h-full flex-col overflow-x-hidden overflow-y-clip overscroll-none bg-vault_background md2:px-2">
      <View className="flex w-full flex-1 flex-col items-center justify-start overflow-x-hidden overflow-y-clip overscroll-none md2:ml-[272px] md2:w-[calc(100%-272px)]">
        <View className="z-above1 mt-1 box-border flex w-full max-w-screen-dt flex-1 flex-row items-center justify-center gap-2 overflow-x-hidden overflow-y-clip overscroll-none lg:mt-2">
          <View className="box-border flex h-full w-full flex-col md2:rounded-t-[20px] md2:border md2:border-solid md2:border-vault_text/10 md2:bg-vault_text/3 lg:w-[438px]">
            <View className="box-border flex w-full flex-row items-center justify-between gap-6 border-0 border-b border-solid border-vault_text/10 px-4 pb-3 pt-5 lg:px-6 lg:pb-6 lg:pt-8">
              <BackButton withVaultTheme className="text-[24px]/[24px]" />
              <Text className="flex-1 text-center font-title text-[18px]/[22px] font-medium text-vault_text md2:text-[22px]/[26px] lg:text-left">
                Text blasts
              </Text>
              <Button
                label=""
                iconOnly
                leadingIcon={faPenToSquare}
                className="text-[24px]/[24px] text-vault_text"
                href={artistNavigationPath(artistHandle, '/messages/create')}
              />
            </View>
            <View className="box-border flex w-full flex-1 px-4">
              <Virtuoso
                itemContent={renderItem}
                data={announcements}
                className="no-scrollbar h-full w-full"
                endReached={hasNextPage && !isFetchingNextPage ? () => fetchNextPage() : undefined}
                components={{
                  Footer,
                }}
              />
            </View>
          </View>
          <View className="box-border hidden h-full flex-1 overflow-hidden md2:border md2:border-solid md2:border-vault_text/10 md2:bg-vault_text/3 lg:flex lg:rounded-t-[20px]">
            {selectedMessageId && announcements.length > 0 && (
              <MassMessageInsightsView
                messageId={selectedMessageId}
                isOwnPage={false}
                artistHandle={artistHandle}
                prefetchedMessage={selectedMessage}
              />
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

function ArtistAnnouncementRow({
  announcement,
  artistHandle,
  isSelected,
  onSelect,
}: {
  announcement: FragmentType<AnnouncementRowFragmentDoc>;
  artistHandle: string;
  isSelected: boolean;
  onSelect: (messageId: string) => void;
}) {
  const { isLg } = useWindow();
  const navigate = useNavigate();

  const {
    id,
    content,
    scheduledAt,
    vault: { artist },
    memberCount,
  } = getFragment(AnnouncementRowFragmentDoc, announcement);

  const dateString = useMemo(() => {
    const now = new Date();
    const scheduled = new Date(scheduledAt);

    const sentText = scheduled > now ? 'Scheduled' : 'Sent';

    return `${sentText} ${formatDate(scheduled, 'E MMM d, y h:mmaa')}`;
  }, [scheduledAt]);

  return (
    <View
      className={twMerge(
        'box-border flex w-full cursor-pointer flex-row gap-3 rounded-xl py-2 no-underline md2:my-1 md2:px-3 md2:hover:bg-vault_text/3',
        isSelected && 'md2:hover:bg-vault_text/3 lg:bg-vault_text/10 lg:hover:bg-vault_text/15',
      )}
      onClick={() => {
        if (!isLg) {
          navigate(artistNavigationPath(artistHandle, `/messages/insights/${id}`));
          return;
        }

        onSelect(id);
      }}
    >
      <ArtistProfileImage
        profileImageUrl={artist?.profileImage?.url}
        className="h-[60px] w-[60px] rounded-full bg-vault_background lg:hidden"
      />
      <View className="flex flex-1 flex-shrink flex-col gap-1">
        <Text className="line-clamp-1 font-title text-[16px]/[18px] font-medium text-vault_text">
          {memberCount} {pluralizeText({ count: memberCount, text: 'member' })}
        </Text>
        <Text className="line-clamp-1 break-all font-base text-[16px]/[20px] font-normal text-vault_text">
          {content}
        </Text>
        <Text className="line-clamp-1 font-base text-[14px]/[18px] font-normal text-vault_text/50">
          {dateString}
        </Text>
      </View>
    </View>
  );
}
