import React, { type FC } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faAngleLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { EVENTS } from '../../types/eventTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { Button } from './Button';

type Props = {
  className?: string;
  onClick?: () => void;
  onBack?: () => void;
  href?: string;
  icon?: IconProp;
  withVaultTheme?: boolean;
};

export const BackButton: FC<Props> = ({
  className,
  onClick,
  onBack,
  href,
  icon = faAngleLeft,
  withVaultTheme,
}) => {
  const { artistHandle } = useArtistHandle();
  const { loggedInUser } = useAuthContext();

  const isOwner =
    (!!artistHandle &&
      loggedInUser?.adminArtists?.some(({ artistLinks }) => artistLinks.includes(artistHandle))) ??
    false;

  const navigate = useNavigate();
  const goBack = () => {
    if (window.history.state.idx != null && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(
        artistHandle != null
          ? artistNavigationPath(artistHandle, isOwner ? '/dashboard' : '/')
          : '/',
      );
    }

    onBack?.();
  };
  return (
    <Button
      iconOnly
      label="Back"
      leadingIcon={icon}
      className={twMerge(
        'select-none text-[24px] text-white outline-none',
        withVaultTheme && 'text-vault_text',
        className,
      )}
      onClick={href == null ? onClick ?? goBack : undefined}
      href={href}
      event={{ type: EVENTS.BACK, properties: { type: 'default' } }}
    />
  );
};
