import {
  FolderItemFragmentDoc,
  ImageItemFragmentDoc,
  makeFragmentData,
  TrackItemFragmentDoc,
  type VaultContentByFolderPositionQuery,
} from '../../graphql/generated';
import { View } from '../common/View';
import { FolderItem } from './items/Folder';
import { ImageItem } from './items/ImageItem';
import { TrackItem } from './items/Track';

export function EmptyVaultPreview({ artistHandle }: { artistHandle: string }) {
  return (
    <View className="grid flex-1 grid-cols-2 items-start gap-x-4 gap-y-[18px] overflow-y-scroll pt-4 scrollbar-none md2:grid-cols-3">
      {items.map((item, i) => {
        if (item.__typename === 'VaultFolder') {
          return (
            <FolderItem
              key={i}
              folderInfo={exampleFolderInfo}
              artistHandle={artistHandle}
              editingFolder={null}
              hasEllipsis={false}
              isOwner={false}
              onClick={null}
            />
          );
        }

        if (item.__typename === 'VaultTrack') {
          return <TrackItem key={i} track={exampleTrackInfo} hasEllipsis={false} />;
        }

        if (item.__typename === 'VaultImage') {
          return (
            <ImageItem
              key={i}
              image={exampleMediaInfo({
                title: 'Example image.jpg',
                url:
                  i === 2
                    ? 'https://d3jznoddta6qri.cloudfront.net/public/empty_vault_image_1.png'
                    : 'https://d3jznoddta6qri.cloudfront.net/public/empty_vault_image_3.png',
              })}
            />
          );
        }

        if (item.__typename === 'VaultVideo') {
          return (
            <ImageItem
              key={i}
              image={exampleMediaInfo({
                title: 'Example video.mp4',
                url: 'https://d3jznoddta6qri.cloudfront.net/public/empty_vault_image_2.png',
              })}
            />
          );
        }

        return null;
      })}
    </View>
  );
}

type Item =
  VaultContentByFolderPositionQuery['vaultContentByFolderPosition']['edges'][number]['node'];

const commonItem = {
  title: '',
  createdAt: new Date().toISOString(),
  folderPosition: 0,
  id: crypto.randomUUID(),
  isFullVersionAvailable: true,
};

const exampleFolderItem: Item = {
  __typename: 'VaultFolder',
  featureAccess: [{ feature: { __typename: 'FreeVaultContent' } }],
  ...commonItem,
};

const exampleTrackItem: Item = {
  __typename: 'VaultTrack',
  featureAccess: [{ feature: { __typename: 'FreeVaultContent' } }],
  ...commonItem,
};

const exampleImageItem: Item = {
  __typename: 'VaultImage',
  featureAccess: [{ feature: { __typename: 'FreeVaultContent' } }],
  ...commonItem,
};

const exampleVideoItem: Item = {
  __typename: 'VaultVideo',
  featureAccess: [{ feature: { __typename: 'FreeVaultContent' } }],
  ...commonItem,
};

const items: Item[] = [
  exampleFolderItem,
  exampleTrackItem,
  exampleImageItem,
  exampleVideoItem,
  exampleFolderItem,
  exampleFolderItem,
  exampleFolderItem,
  exampleFolderItem,
  exampleImageItem,
  exampleFolderItem,
  exampleFolderItem,
  exampleFolderItem,
];

const exampleFolderInfo = makeFragmentData(
  {
    childrenVaultCount: 7,
    createdAt: new Date().toDateString(),
    id: crypto.randomUUID(),
    linkValue: 'link-value',
    nestedChildrenVaultContentCount: 0,
    parentVaultContentId: null,
    title: 'Example folder',
    vault: {
      activeSubscription: null,
      id: crypto.randomUUID(),
    },
  },
  FolderItemFragmentDoc,
);

const exampleTrackInfo = makeFragmentData(
  {
    commentMessageCount: 0,
    createdAt: new Date().toDateString(),
    downloadEnabled: false,
    duration: 185,
    featureAccess: [
      {
        feature: {
          __typename: 'FreeVaultContent',
        },
      },
    ],
    id: crypto.randomUUID(),
    linkValue: 'link-value',
    parentVaultContentId: null,
    title: 'Example song.wav',
    vaultId: crypto.randomUUID(),
    vault: {
      id: crypto.randomUUID(),
      isUserArtistAdmin: true,
      activeSubscription: {
        id: crypto.randomUUID(),
        createdAt: new Date().toDateString(),
      },
      artist: {
        id: crypto.randomUUID(),
        membershipCardImage: null,
        name: 'Artist',
        profileImage: null,
      },
    },
    isFullVersionAvailable: true,
  },
  TrackItemFragmentDoc,
);

const exampleMediaInfo = ({ url, title }: { url: string; title: string }) =>
  makeFragmentData(
    {
      blurredMediaUrl: null,
      createdAt: new Date().toDateString(),
      featureAccess: [{ feature: { __typename: 'FreeVaultContent' } }],
      id: crypto.randomUUID(),
      linkValue: 'link-value',
      parentVaultContentId: null,
      title,
      uploadedMedia: {
        id: crypto.randomUUID(),
        mediaType: 'IMAGE',
        url,
        smallImageMedia: [],
      },
      vaultId: crypto.randomUUID(),
      vault: {
        id: crypto.randomUUID(),
        isUserArtistAdmin: true,
        activeSubscription: {
          id: crypto.randomUUID(),
          createdAt: new Date().toDateString(),
        },
        artist: {
          id: crypto.randomUUID(),
          profileImage: null,
        },
      },
    },
    ImageItemFragmentDoc,
  );
