import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFuture } from 'date-fns';
import millify from 'millify';
import { Link } from 'react-router-dom';
import {
  faFloppyDisk,
  faReceipt,
  faWaveform,
  faWaveformLines,
} from '@soundxyz/font-awesome/pro-light-svg-icons';
import {
  PrivateReleaseCampaignEventItemFragmentDoc,
  PublicReleaseCampaignEventItemFragmentDoc,
  ReleaseCampaignEventItemFragmentDoc,
} from '../../../graphql/generated';
import { type FragmentType, getFragment } from '../../../graphql/generated';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { Image } from '../../common/Image';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

export const ReleaseCampaignEventItem = React.memo(
  ({
    eventId,
    releaseCampaign,
    receiptCount,
  }: {
    eventId: string;
    releaseCampaign: FragmentType<ReleaseCampaignEventItemFragmentDoc>;
    receiptCount: number | null;
  }) => {
    const fragment = getFragment(ReleaseCampaignEventItemFragmentDoc, releaseCampaign);

    const campaign =
      fragment.__typename === 'ReleaseCampaignPublicInfo'
        ? getFragment(PublicReleaseCampaignEventItemFragmentDoc, fragment)
        : fragment.__typename === 'ReleaseCampaignPrivateInfo'
          ? getFragment(PrivateReleaseCampaignEventItemFragmentDoc, fragment)
          : null;

    if (!campaign) return null;

    const {
      title,
      currentState,
      releaseDate,
      coverImage,
      initialReleaseImageUrl,
      linkValue,
      artist: { isAuthUserAdmin, linkValue: artistHandle, profileImage },
    } = campaign;

    const releaseImage = coverImage?.url ?? initialReleaseImageUrl ?? profileImage?.url;

    const isPresave = currentState === 'PRESAVE' && releaseDate && isFuture(releaseDate);

    return (
      <Link
        to={`${artistNavigationPath(
          artistHandle,
          isAuthUserAdmin ? `/event/insights/${eventId}` : `/s/${linkValue}`,
        )}`}
        className="flex w-full flex-row items-center justify-between gap-4 no-underline transition-all duration-200 ease-in hover:opacity-80"
      >
        <View className="flex h-16 max-w-[80%] justify-start gap-4">
          {releaseImage ? (
            <Image
              src={releaseImage}
              alt={title}
              height={64}
              width={64}
              className="aspect-square h-16 w-16 flex-shrink-0 rounded-md bg-neutral500 object-cover"
            />
          ) : (
            <View className="flex aspect-square w-16 flex-shrink-0 items-center justify-center rounded-md  outline outline-1 outline-vault_text/10">
              <FontAwesomeIcon icon={faWaveformLines} className="text-[20px] text-vault_text" />
            </View>
          )}
          <View className="flex w-full flex-col justify-center gap-2">
            <View className="flex flex-row items-center gap-2">
              <Text className="line-clamp-1 font-title text-[18px]/[20px] font-medium text-vault_text">
                {title}
              </Text>
            </View>
            <View className="flex flex-col gap-0.5">
              <span className="flex items-center gap-1 text-[14px]/[16px] font-normal">
                {isPresave ? (
                  <>
                    <FontAwesomeIcon icon={faFloppyDisk} className=" text-vault_text/60" />
                    <Text className="font-base text-vault_text/60">Pre-save</Text>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faWaveform} className=" text-vault_text/60" />
                    <Text className="font-base text-vault_text/60">Stream</Text>
                  </>
                )}
              </span>
            </View>
          </View>
        </View>
        {isAuthUserAdmin && receiptCount != null && (
          <View className="flex w-12 flex-shrink-0 flex-row items-center justify-end gap-2 pr-2 text-[14px] font-normal text-vault_text opacity-60">
            <View className="line-clamp-1 flex flex-shrink flex-row justify-end text-[14px]">
              <Text>{millify(receiptCount, { precision: 1, lowercase: true })}</Text>
            </View>
            <FontAwesomeIcon icon={faReceipt} className="text-[14px]" />
          </View>
        )}
      </Link>
    );
  },
);

ReleaseCampaignEventItem.displayName = 'ReleaseCampaignEventItem';
